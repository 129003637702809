import React from "react"
import styled from "styled-components"
import { 
  H1,
  P
} from "../../theme/page-elements/elements"

const DictContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  margin: auto;
  width: 80vw;
  max-width: 50rem;
  flex-direction: column;
  text-align: left;
  box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.2);
  border-radius: 15px;
  padding: 20px;
  margin-top: 5vh;
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.light};
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

export default function Definition({ word, use, definition }) {

    return(
        <DictContainer>
            <H1 style={{marginBottom: "0.25em"}}>{ word }</H1>
            <P><em>{ use }</em></P>
            <Divider />
            <P>{ definition }</P>
        </DictContainer>
    )
}