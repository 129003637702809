import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import Definition from "../components/Home/Definition"
import { 
  Block,
  SubBlock,
  FlexGroup,
  H1,
  H2,
  LeadP,
  P,
  SlopeTL,
  SlopeBR,
  Card,
  LinkBtn,
  IconListItem,
  HeroImgWide,
  HeroImgNarrow
} from "../theme/page-elements/elements"
import Equals from "../assets/equal.svg"

const CardContainer = styled.div`
  max-width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  margin: auto;
  width: 90%;
  padding: 20px;
  max-width: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.2);
  border-radius: 15px;
`

const Strong = styled.strong`
color: ${({ theme }) => theme.palette.warmcont};
`

export default function Index() {
  const data = useStaticQuery(getLogo)

  return (
    <Layout>
      <Block>
        <H1>Introducing Neohealth</H1>
        <P>Technology. Analytics. Innovation. <Strong>Healthcare.</Strong></P>
        <HeroImgWide>
          <Img
            fluid={data.hero.childImageSharp.fluid}
            style={{ width: '60%'}}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeroImgWide>
        <HeroImgNarrow>
          <Img
            fluid={data.heroNarrow.childImageSharp.fluid}
            style={{ width: '100%'}}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeroImgNarrow>
        <FlexGroup>
          <LinkBtn to="#neohealth" label= "What is Neohealth?" alternate/>
          <LinkBtn to="#equal" label= "About Equal Neohealth" alternate reverse/>
        </FlexGroup>
      </Block>

      <Block 
        id="neohealth"
        gradient
      >
        <Definition
          word="neohealth"
          use="noun"
          definition="The notion that redesigned healthcare services effectively utilising technology and analytics will overcome limitations of traditional service delivery." 
        />
      </Block>
      <Block alternate nopaddingTop>
        <LeadP 
          alternate>
            More than telehealth and wearables
        </LeadP>
        <P
         style={{ paddingTop: '0.5em'}}>The use of technology and analytics in healthcare to:</P>
        <FlexGroup style={{marginTop: "1em"}}>
          <IconListItem 
            heading="Streamline"
            text="Automate admin to invest in providing care."
            queryRef="automation"
          />
          <CardContainer>
            <Img
              fluid={data.plus.childImageSharp.fluid}
              style={{ width: '100px'}}
              imgStyle={{ objectFit: 'contain' }}
            />
          </CardContainer>
          <IconListItem
            heading="Scale"
            text="Maintain quality and governance while expanding."
            queryRef="growth"
          />
          <CardContainer>
            <Img
              fluid={data.plus.childImageSharp.fluid}
              style={{ width: '100px'}}
              imgStyle={{ objectFit: 'contain' }}
            />
          </CardContainer>
          <IconListItem
            heading="Innovate"
            text="Enhance clinical and service outcomes."
            queryRef="innovate"
          />
          <CardContainer>
            <Equals style={{height: "30px"}}/>
          </CardContainer>
          <CardContainer style={{maxWidth: "500px"}}>
            <H1>Neohealth</H1>
          </CardContainer>
        </FlexGroup>
      </Block>

      <Block
       id="equal"
       nopaddingBtm>
        <H2>About Equal</H2>
            <P style={{paddingBottom: "0.5em"}}>Equal is a voluntary service to advocate for effective use of technology and analytics in the progression of healthcare.</P>
            <P style={{paddingBottom: "0.5em"}}>Equal is designed to work with small-medium organisations without the expertise or resources to get started on their tech and analytics journey.</P>
            <div style={{paddingBottom: "2em"}}>
              <LinkBtn to="about" label= "More About Equal"/> 
            </div>
      </Block>
      <Block gradient nopaddingTop>
          <Container>
            <Img
              fluid={data.integrated.childImageSharp.fluid}
              style={{ width: '100%'}}
              imgStyle={{ objectFit: 'contain' }}
            />

          </Container>
      </Block>
      <Block style={{paddingTop: "0"}} alternate>
        <H2>Services</H2>
        <FlexGroup>
          <Card 
            heading="Ideation"
            queryRef="ideationService" 
            text="Identify ways to progress your operations."
            link="/services#service-ideation"
          />
          <Card 
            heading="Prototypes"
            queryRef="protoService" 
            text="Small scale demos targeting your pain points."
            link="/services#service-prototype"
          />
        </FlexGroup>
      </Block>
    </Layout>
  )
}

export const getLogo = graphql`
query {
  logo: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  icon: file(relativePath: { eq: "icon.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  hero: file(relativePath: { eq: "connected-isometric.png" }) {
    childImageSharp {
      fluid(maxWidth: 2048, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  heroNarrow: file(relativePath: { eq: "connected-isometric.png" }) {
    childImageSharp {
      fluid(maxWidth: 2048, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  plus: file(relativePath: { eq: "plus.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  equals: file(relativePath: { eq: "equals.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  text: file(relativePath: { eq: "text-only.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  integrated: file(relativePath: { eq: "integrated-tech.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`
